import React from "react";

const PhoneStyle = {
  fontSize: "15px",
  color: "#fff",
  margin: "0px",
  paddingBottom: "8px"
};
const PhoneLink = {
  textDecoration: "none",
  color: "#fff",
  fontSize: "11px",
  paddingRigth: "5px",
  marginRigth: "5px",
  lineHeight: "0",
};

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "");
}

export default function Phone(phone) {
  return (
    <p style={PhoneStyle}>
      <a href={`tel:+33 ${telWithoutSpaces(phone.phone)}`}
        style={PhoneLink}
      >
        {phone.phone}
      </a>
      <br />
    </p>
  );
}
