import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import "./style.css";

const Wrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const Container = {
  width: "100%",
  display: "block",
};
const InputWrapper = {
  margin: "0 0",
  paddingTop: "20px",
};
const leftPart = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%"
}
const inputWrapper = {
  display: "flex",
  flexDirection: "row",
  gap: "15px"
}

const userData = {
  firstname: "Prénom",
  lastname: "Nom",
  urlImage: "",
  location: "Luxembourg & Metz",
  job: "Titre du poste",
  phone: "+33 X XX XX XX XX",
  website: "https://alchemee.io/",
  linkedin: "linkedin.com",
};

export default function App() {
  const [user, setUser] = useState(userData);
  const [isChecked, setIsChecked] = useState(false);
  const [typeImage, setTypeImage] = useState('gif');

  const userInfoChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  function copyToClipboard() {
    let tableToCopy = document.querySelector('.wrapper-table')
    let range = document.createRange();
    range.selectNode(tableToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }

  function responseCopy() {
    let button = document.querySelector('button');
      button.classList.add('copied');
    setTimeout(() => {
      button.classList.remove('copied');
    }, 2000);
  }

  return (
    <div style={Wrapper}>
      <div style={Container}>
        <section className="formulaire">
          <div style={leftPart}>
            <SignatureFormatter user={user} message={isChecked} typeImage={typeImage} />
          </div>
          <div className="rightPart">
            <h1 className="title">Signature Generator</h1>
            <h2 className="title">By my client is rich</h2>
            <p className="inputLabel">Coordonnées</p>
            {/* ***************          NOM ET PRENOM        *************** */}
            <div style={inputWrapper}>
              <input
                name="firstname"
                onChange={userInfoChange}
                value={user.firstname}
                placeholder="Prénom"
                onBlur={() => {
                  user.firstname === "" ? setUser({ ...user, firstname: userData.firstname }) : setUser({ ...user, firstname: user.firstname });
                }}
                onFocus={() => {
                  user.firstname === userData.firstname ? setUser({ ...user, firstname: "" }) : setUser({ ...user, firstname: user.firstname });
                }}
              />
                <input
                  name="lastname"
                  onChange={userInfoChange}
                  value={user.lastname}
                  placeholder="Nom"
                  onBlur={() => {
                    user.lastname === "" ? setUser({ ...user, lastname: userData.lastname }) : setUser({ ...user, lastname: user.lastname });
                  }}
                  onFocus={() => {
                    user.lastname === userData.lastname ? setUser({ ...user, lastname: "" }) : setUser({ ...user, lastname: user.lastname });
                  }}
                />
            </div>
            {/* ***************          TITRE DU POSTE        *************** */}
            <div style={InputWrapper}>
              <input
                name="job"
                onChange={userInfoChange}
                value={user.job}
                placeholder="Titre du poste"
                onBlur={() => {
                  user.job === "" ? setUser({ ...user, job: userData.job }) : setUser({ ...user, job: user.job });
                }}
                onFocus={() => {
                  user.job === userData.job ? setUser({ ...user, job: "" }) : setUser({ ...user, job: user.job });
                }}
              />
            </div>
            {/* ***************          LOCALISATION       *************** */}
            <div style={InputWrapper}>
              <input
                name="location"
                onChange={userInfoChange}
                value={user.location}
                placeholder="Localisation"
                onBlur={() => {
                  user.location === "" ? setUser({ ...user, location: userData.location }) : setUser({ ...user, location: user.location });
                }}
                onFocus={() => {
                  user.location === userData.location ? setUser({ ...user, location: "" }) : setUser({ ...user, location: user.location });
                }}
              />
            </div>
            {/* ***************          TELEPHONE        *************** */}
            <div style={InputWrapper}>
              <input
                name="phone"
                onChange={userInfoChange}
                value={user.phone}
                placeholder="Téléphone"
                onBlur={() => {
                  user.phone === "" ? setUser({ ...user, phone: userData.phone }) : setUser({ ...user, phone: user.phone });
                }}
                onFocus={() => {
                  user.phone === userData.phone ? setUser({ ...user, phone: "" }) : setUser({ ...user, phone: user.phone });
                }}
              />
            </div>
            {/* ***************          RESEAUX SOCIAUX        *************** */}
            <p className="inputLabel">Social média</p>
            {/* ***************          LINKEDIN        *************** */}
            <div style={InputWrapper}>
              <input
                name="linkedin"
                onChange={userInfoChange}
                value={user.linkedin}
                placeholder="Linkedin"
                onBlur={() => {
                  user.linkedin === "" ? setUser({ ...user, linkedin: userData.linkedin }) : setUser({ ...user, linkedin: user.linkedin });
                }}
                onFocus={() => {
                  user.linkedin === userData.linkedin ? setUser({ ...user, linkedin: "" }) : setUser({ ...user, linkedin: user.linkedin });
                }}
              />
            </div>

            <button
              className="copyButton"
              onClick={() => {
                copyToClipboard();
                responseCopy();
              }}
            ><p className="btnCopy"></p></button>
          </div>
        </section>
      </div>
    </div>
  );
}
