import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const PhoneLink = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: compagnyColorPrimary,
  lineHeight: "0",
};
const imgSocial = {
  paddingRight: "10px",
  margin: "auto"
};

export default function Linkedin({ linkedin }) {
  const linkedinUrl = linkedin.startsWith('http://') || linkedin.startsWith('https://')
    ? linkedin
    : `https://${linkedin}`;

  return (
    <>
      <a href={linkedinUrl} style={PhoneLink} target="_blank" rel="noopener noreferrer">
        <img style={imgSocial} src={`https://files.myclientisrich.com/${client}/linkedin.png`} alt="LinkedIn" height={15} width={15} />
      </a>
      <br />
    </>
  );
}
