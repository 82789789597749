import React from "react";

const Mail = {
  margin: "0px 0px 0px",
  fontSize: "11px",
  lineHeight: "1.38",
  textDecoration: "none",
  color: "#666"
};


export default function PostalAdress(location) {
  return (
    <p style={Mail}>
      {location.location}
    </p>
  );
}
