import React, { useEffect } from "react";
import Phone from "./items/phone";
import PostalAdress from "./items/postalAdress";
import Linkedin from "./items/linkedin";

const config = require("../../config.json")
const client = config.APP_CLIENT;
const imgName = config.APP_IMG_NAME;
const compagnyName = config.APP_COMPAGNY_NAME;
const colorBackgroundTable = config.APP_COLORBACKGROUNDTABLE;
const widthImage = config.APP_WIDTHIMAGE;

const ContainerSocial = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "0px",
  paddingLeft: "20px"
};
const SignatureStyle = {
  borderSpacing: "0px",
  backgroundColor: colorBackgroundTable,
};
const ImageWrapper = {
  position: "relative",
  width: widthImage,
  height: "165px",
  marginLeft: "20px",
  objectFit: "cover",
  minWidth: "150px",
};
const Image = {
  width: widthImage,
  margin: "auto",
  display: "block",
};
const TextWrapper = {
  marginLeft: "20px",
  fontFamily: "Arial",
  lineHeight: "1.45",
  minWidth: "200px",
};
const Name = {
  fontSize: "18px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "1",
  display: "inline-block",
  color: "#fff",
  padding: "0px"
};
const LastName = {
  fontSize: "18px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "1",
  display: "inline-block",
  color: "#BAFFD8",
  padding: "0px"
};
const Job = {
  margin: "3px 0px 10px 0px",
  fontSize: "11px",
  color: "#fff",
  fontWeight: "700",
  width: "150px",
};
const Pole = {
  margin: "0px 0px 5px",
  fontSize: "11px",
  color: "#fff",
};
const Link = {
  color: "#C48EFB",
  fontWeight: "bold",
  fontSize: "11px",
  textDecoration: "none",
  border: "1px solid #335456",
  padding: "2px 5px"
};
const StandardURL = {
  margin: "10px 0px 10px 0px",
  fontSize: "15px"
};
const border = {
  borderLeft: "1px solid #3F3F3F",
  paddingLeft: "20px",
  paddingTop: "5px"
}
const middle = {
  verticalAlign: 'middle'
}

function constructImgUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${imgName}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, typeImage }) => {
  useEffect(() => {
    document.title = `${compagnyName} | Signature`;
    const metaCharset = document.createElement('meta');
    metaCharset.setAttribute('charSet', 'utf-8');
    document.head.appendChild(metaCharset);
    return () => {
      document.head.removeChild(metaCharset);
    };
  }, [compagnyName]);


  let urlUploadsImg = "";
  if (user.urlImage === "" || user.urlImage === null) {
    if (typeImage === "static") {
      urlUploadsImg = constructImgUrl(client, 'jpg');
    } else if (typeImage === "gif") {
      urlUploadsImg = constructImgUrl(client, 'gif');
    }
  }

  return (
    <div>
      <div className='wrapper-table'>
        <table className="signature" style={SignatureStyle}>
          <tbody>
            <tr>
              <td className="image__wrapper" style={ImageWrapper}>
                <img src={urlUploadsImg} style={Image} alt="logo" />
              </td>

              <td className="text__wrapper" style={TextWrapper}>
                <div style={border}>
                  <p style={Object.assign({}, Name)}>
                    {user.firstname}
                    &nbsp;
                  </p>
                  <p style={Object.assign({}, LastName)}>
                    {user.lastname}
                  </p>
                  <p style={Job}>{user.job}</p>
                  {user.availability ? (
                    <p style={Pole}>{user.availability}</p>
                  ) : null}

                  {user.phone ? (
                    <Phone phone={user.phone} />
                    ) : null}

                  {user.location ? (
                    <PostalAdress location={user.location} />
                  ) : null}

                  <table style={{borderSpacing: 0}}>
                    <tr>
                      <td>
                      <p style={StandardURL}>
                        <a href={user.website} target="_blank" style={Link}>www.alchemee.io</a>
                      </p>
                    </td>
                    <td style={middle}>
                      <p style={ContainerSocial}>
                        {user.linkedin ? (
                          <Linkedin linkedin={user.linkedin} />
                        ) : null}
                      </p>
                    </td>
                  </tr>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SignatureFormatter;
